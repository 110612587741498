import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@material-ui/core'
import { TextSm } from '@system'
import get from 'lodash/get'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  filter: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(12px, 6px) scale(1)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate(12px, 7px) scale(1)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translate(12px, 8px) scale(1)',
      },
    },
    '& label': {
      width: 'calc(100% - 15px)',
      '& p': {
        whiteSpace: 'nowrap',
        width: 'calc(100% - 23px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  filterInput: {
    height: '32px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    '&>div': {
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&>svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
  },
  textField: {
    '& input': {
      padding: '7px 10px',
      height: '32px',
      boxSizing: 'border-box',
    },
  },
  multiSelectFilterChip: {
    position: 'absolute',
    width: '90%',
    zIndex: '1',
    pointerEvents: 'none',
    borderRadius: '0',
    height: '20px',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>span': {
      padding: '0',
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '97.5%',
    },
  },
  option: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const SingleSelectFilter = (props) => {
  const classes = useStyles()
  const { filterDataPath, title, filterResultsLogic, filters } = props.blok
  const fieldOptions = [''].concat(
    get(props.blok, 'options.[0].fieldOptions', [])
  )

  const [options, setOptions] = useState([])

  const onChangeFilter = (event) => {
    setOptions([event.target.value])
    props.blok.onChangeFilters(event, filterDataPath)
    props.blok.updateFilterLogicRules(
      filterResultsLogic,
      'narrowsResults',
      filterDataPath
    )
  }

  const values =
    !!filters &&
    filters.filter(
      (filter) => filter.filterBy.toLowerCase() === filterDataPath.toLowerCase()
    )

  const numOfFiltersSelected =
    values.length > 0 && values.reduce((acc, value) => acc).filterSelect ? 1 : 0

  return (
    <SbEditable content={props.blok}>
      <FormControl variant="outlined" className={classes.filter}>
        <>
          {numOfFiltersSelected && numOfFiltersSelected !== '' ? (
            <Chip
              color="primary"
              label={`${values.reduce((acc, value) => acc).filterSelect}`}
              className={classes.multiSelectFilterChip}
            ></Chip>
          ) : (
            <InputLabel htmlFor={title}>
              <TextSm>{title}</TextSm>
            </InputLabel>
          )}
          <Select
            name={filterDataPath}
            onChange={onChangeFilter}
            value={
              filters.length === 1 && filters[0].filterBy === ''
                ? ''
                : values.length > 0
                ? values.reduce((acc, value) => acc).filterSelect
                : ''
            }
            className={classes.filterInput}
          >
            {fieldOptions &&
              fieldOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <TextSm className={classes.option}>{option}</TextSm>
                </MenuItem>
              ))}
          </Select>
        </>
      </FormControl>
    </SbEditable>
  )
}

export default SingleSelectFilter
